// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-suvs-tsx": () => import("./../../../src/pages/suvs.tsx" /* webpackChunkName: "component---src-pages-suvs-tsx" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-car-table-page-by-size-and-year-tsx": () => import("./../../../src/templates/carTablePageBySizeAndYear.tsx" /* webpackChunkName: "component---src-templates-car-table-page-by-size-and-year-tsx" */),
  "component---src-templates-car-table-page-by-size-tsx": () => import("./../../../src/templates/carTablePageBySize.tsx" /* webpackChunkName: "component---src-templates-car-table-page-by-size-tsx" */),
  "component---src-templates-car-table-page-by-year-tsx": () => import("./../../../src/templates/carTablePageByYear.tsx" /* webpackChunkName: "component---src-templates-car-table-page-by-year-tsx" */),
  "component---src-templates-car-table-page-tsx": () => import("./../../../src/templates/carTablePage.tsx" /* webpackChunkName: "component---src-templates-car-table-page-tsx" */),
  "component---src-templates-single-car-page-tsx": () => import("./../../../src/templates/singleCarPage.tsx" /* webpackChunkName: "component---src-templates-single-car-page-tsx" */)
}

